import { withErrorBoundary } from "@sentry/nextjs";
import classNames from "classnames";
import React, { FC } from "react";

import { ContentCard } from "../../main/components/contentCard/ContentCard";
import { ContentCardRichBody } from "../../main/components/contentCard/ContentCardRichBody";
import { PreloadedComments } from "../hooks/useComments";
import { PostDto } from "../models/dto/postDto";
import { PostFooter } from "./PostFooter";
import { PostHeader } from "./PostHeader";
import { SomethingWentWrong } from "./SomethingWentWrong";

interface Props {
    post: PostDto;
    preloadedComments?: PreloadedComments;
    onDeleted?: () => void;
    hideFooter?: boolean;
}

let Post: FC<Props> = ({ post, preloadedComments, onDeleted, hideFooter }) => {
    return (
        <ContentCard className={classNames({ "pb-4": hideFooter })}>
            <PostHeader post={post} />

            <ContentCardRichBody content={post.content} />

            {!hideFooter && (
                <>
                    {post.content?.text && <hr />}
                    <PostFooter post={post} preloadedComments={preloadedComments} onDeleted={onDeleted} />
                </>
            )}
        </ContentCard>
    );
};

Post = withErrorBoundary(Post, { fallback: <SomethingWentWrong variant="white" text="An error occurred while displaying this post." /> });
export { Post };
