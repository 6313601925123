import { faComment } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/nextjs";
import React, { FC, useState } from "react";
import Button from "react-bootstrap/Button";

import { NostalgiaBtn } from "../../main/components/buttons/NostalgiaBtn";
import { showToast } from "../../main/services/toastService";
import { boolProp } from "../../main/utils/boolProp";
import { PreloadedComments } from "../hooks/useComments";
import { PostDto } from "../models/dto/postDto";
import { postService } from "../services/postService";
import { PostCommentList } from "./PostCommentList";
import { PostOrCommentOptionsDropdown } from "./PostOrCommentOptionsDropdown";

interface Props {
    post: PostDto;
    preloadedComments: PreloadedComments;
    onDeleted?: () => void;
}

export const PostFooter: FC<Props> = ({ post, preloadedComments, onDeleted }) => {
    const [commentsVisible, setCommentsVisible] = useState(Boolean(preloadedComments));
    const [givingNostalgia, setGivingNostalgia] = useState(false);
    const [nostalgiaReaction, setNostalgiaReaction] = useState(post.nostalgiaReaction);
    const [nostalgiaCount, setNostalgiaCount] = useState(post.nostalgiaCount);
    const [addedComments, setAddedComments] = useState(0); // TODO use shared context to directly modify post state
    const [deletedComments, setDeletedComments] = useState(0);

    async function changeNostalgiaReaction() {
        const previous = { count: nostalgiaCount, reaction: nostalgiaReaction };

        setNostalgiaReaction(!previous.reaction);
        if (previous.reaction) {
            setNostalgiaCount(previous.count - 1 || 0);
        } else {
            setNostalgiaCount(previous.count + 1);
        }

        setGivingNostalgia(true);
        try {
            const { data } = await postService.changeNostalgiaReaction(post.epochStartingYear, post.id);
            setNostalgiaReaction(data.value);
            setNostalgiaCount(data.count);
        } catch (error) {
            setNostalgiaReaction(previous.reaction);
            setNostalgiaCount(previous.count);
            showToast("An error occurred while changing your post reaction.");
            Sentry.captureException(error);
        } finally {
            setGivingNostalgia(false);
        }
    }

    async function deletePost() {
        try {
            await postService.delete(post.epochStartingYear, post.id);
        } catch (error) {
            showToast("An error occurred while deleting the post.");
            Sentry.captureException(error);
            return;
        }
        onDeleted();
    }

    // TODO refactor deleting operation with React Context or Zustand
    return (
        <div>
            <div className="d-flex justify-content-between mb-3 card-body-margin-x">
                <div>
                    <NostalgiaBtn
                        disabled={boolProp(givingNostalgia)}
                        onClickWithAuthHandling={changeNostalgiaReaction}
                        clicked={boolProp(nostalgiaReaction)}
                        count={nostalgiaCount}
                    />
                    <Button onClick={() => setCommentsVisible((v) => !v)} variant="light" size="sm" className="ms-3">
                        <FontAwesomeIcon icon={faComment} fixedWidth className="me-2" />
                        {post.commentsCount + addedComments - deletedComments}
                    </Button>
                </div>

                <PostOrCommentOptionsDropdown id={post.id} name="post" username={post.author.username} onDelete={onDeleted && deletePost} />
            </div>

            {commentsVisible && (
                <PostCommentList
                    post={post}
                    preloaded={preloadedComments}
                    onPublished={() => setAddedComments(addedComments + 1)}
                    onDeleted={() => setDeletedComments(deletedComments + 1)}
                />
            )}
        </div>
    );
};
