import classNames from "classnames";
import { FC } from "react";
import Spinner from "react-bootstrap/Spinner";

import { BaseProps } from "../../models/props/baseProps";

export const LoadingText: FC<BaseProps> = (props) => {
    return (
        // fixed height solves problem of dynamically changing height
        <div
            className={classNames("d-flex flex-row justify-content-center align-items-center", props.className)}
            style={{ height: "45px" }}
        >
            <Spinner as="span" animation="border" role="status" aria-hidden="true" className="me-2" /> Loading...
        </div>
    );
};
