import classNames from "classnames";
import { FC } from "react";

import { BaseProps } from "../../models/props/baseProps";
import styles from "./GreyTextBtn.module.scss";

interface Props extends BaseProps {
    clicked?: boolean;
    onClick?: () => void;
    children: React.ReactNode;
}

export const GreyTextBtn: FC<Props> = ({ className, clicked, onClick, children }) => {
    return (
        <span
            onClick={() => onClick && onClick()}
            className={classNames(className, styles["gt-btn"], "clickable-underline text-secondary", { "text-success": clicked })}
        >
            {children}
        </span>
    );
};
