import { withErrorBoundary } from "@sentry/nextjs";
import React, { FC, useEffect } from "react";

import { useUser } from "../../auth/authContext";
import { GreyTextBtn } from "../../main/components/buttons/GreyTextBtn";
import { LoadingText } from "../../main/components/loadingScreens/LoadingText";
import { PreloadedComments, useComments } from "../hooks/useComments";
import { PostCommentDto } from "../models/dto/postCommentDto";
import { PostDto } from "../models/dto/postDto";
import { PostCommentView } from "../models/views/postCommentView";
import { postService } from "../services/postService";
import { PostCommentWithReplies } from "./PostCommentWithReplies";
import { SomethingWentWrong } from "./SomethingWentWrong";
import { WriteComment } from "./WriteComment";

interface Props {
    post: PostDto;
    preloaded: PreloadedComments;
    focusWriteInput?: boolean;
    onPublished: () => void;
    onDeleted: () => void;
}

let PostCommentList: FC<Props> = (props) => {
    const { isLoading, comments, handleAdded, handleDeleted, hasMore, loadBatch, error } = useComments(
        props.post.commentsCount,
        (lastId) => postService.getComments(props.post.epochStartingYear, props.post.id, lastId),
        props.preloaded
    );
    const { user } = useUser();

    useEffect(() => loadBatch(), []);

    function handlePublished(comment: PostCommentDto) {
        const view = comment as PostCommentView;
        view.justAdded = true;
        handleAdded(comment, "desc");
        props.onPublished();
    }

    function handleDeletedPost(id: string) {
        handleDeleted(id);
        props.onDeleted();
    }

    return (
        <>
            <hr />
            <div className="pb-3">
                {error && <SomethingWentWrong className="mx-4" variant="light" onTryAgain={loadBatch} />}

                {!error && (
                    <>
                        {user && (
                            <WriteComment
                                className="card-body-padding-x mt-3"
                                post={props.post}
                                onPublished={handlePublished}
                                focus={props.focusWriteInput}
                            />
                        )}

                        {comments.map((i) => (
                            <PostCommentWithReplies
                                key={"comment:" + i.id}
                                post={props.post}
                                comment={i}
                                onDeleted={() => handleDeletedPost(i.id)}
                            />
                        ))}

                        {!user && !isLoading && comments.length === 0 && <span className="px-4 text-secondary">No comments yet...</span>}

                        {hasMore && !isLoading && (
                            <div className="px-4 mt-2">
                                <GreyTextBtn onClick={loadBatch}>View more comments</GreyTextBtn>
                            </div>
                        )}

                        {isLoading && <LoadingText className="fade-in" />}
                    </>
                )}
            </div>
        </>
    );
};

PostCommentList = withErrorBoundary(PostCommentList, {
    fallback: <SomethingWentWrong className="mx-4" variant="light" text="An error occurred while displaying comments." />,
});
export { PostCommentList };
