import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";
import { Alert } from "react-bootstrap";

interface Props {
    children: React.ReactNode;
}

export const Fallback: FC<Props> = ({ children }) => {
    return (
        <Alert className="m-2" variant="warning">
            <FontAwesomeIcon className="me-2" icon={faExclamationTriangle} /> {children}
        </Alert>
    );
};
