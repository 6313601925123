import React, { FC } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { ProtectedEmail } from "../../main/components/ProtectedEmail";

interface Props {
    id: string;
    name: "post" | "comment";
    show: boolean;
    onHide: () => void;
}

export const ReportContentModal: FC<Props> = (props) => {
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Report {props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                If you think this {props.name} contains abusive content or spam, please email{" "}
                <ProtectedEmail className="fw-bold" email="support@nostwave.com" /> with the following id as a {props.name} reference:{" "}
                <span className="fw-bold">
                    {props.name}:{props.id}
                </span>
                .<br />
                <br />
                Thank you!
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
