import React, { FC } from "react";
import TextTransition, { presets } from "react-text-transition";

import { BaseProps } from "../../models/props/baseProps";

interface Props extends BaseProps {
    clicked: boolean;
    count: number;
}

export const AnimatedCounter: FC<Props> = ({ className, clicked, count }) => {
    return <TextTransition className={className} direction={clicked ? "down" : "up"} text={count} springConfig={presets.default} />;
};
