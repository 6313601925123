import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { FC } from "react";
import Button from "react-bootstrap/Button";

import { CommonErrorMsg } from "../../main/language-helpers/errorMessages";
import { BaseProps } from "../../main/models/props/baseProps";
import styles from "./SomethingWentWrong.module.scss";

interface Props extends BaseProps {
    variant: "white" | "light";
    text?: string;
    onTryAgain?: () => void;
}

export const SomethingWentWrong: FC<Props> = ({ className, variant, text, onTryAgain }) => {
    return (
        <div
            className={classNames(
                "my-2 p-4 rounded text-center",
                { "bg-white": variant === "white" },
                { "bg-light": variant === "light" },
                className
            )}
        >
            <FontAwesomeIcon icon={faCogs} className={styles.icon} />
            <div className={`${styles.header} fw-bold mt-2 mb-1`}>Something Went Wrong</div>
            <div>{text || CommonErrorMsg.occurredTryLater}</div>
            {onTryAgain && (
                <Button onClick={onTryAgain} variant="secondary" className="mt-3">
                    Try again
                </Button>
            )}
        </div>
    );
};
