import React, { FC } from "react";
import Spinner from "react-bootstrap/Spinner";
import TextareaAutosize from "react-textarea-autosize";

import { useUser } from "../../auth/authContext";
import { Avatar } from "../../main/components/Avatar";
import { TextLimitCounter } from "../../main/components/TextLimitCounter";
import { useYear } from "../../main/hooks/useYear";
import { BaseProps } from "../../main/models/props/baseProps";
import { POST_COMMENT_MAX_LENGTH } from "../../main/utils/globalConst";

interface Props extends BaseProps {
    type: "comment" | "reply";
    focus: boolean;
    isBusy: boolean;
    value: string;
    onChange: (text: string) => void;
    onPublish: () => void;
}

export const CommentOrReply: FC<Props> = (props) => {
    const { user } = useUser();
    const year = useYear();

    function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === "Enter" && !e.shiftKey && props.value.trim().length > 0) {
            e.preventDefault();
            props.onPublish();
        }
    }

    return (
        <div className={props.className}>
            <div className="d-flex">
                <div style={{ marginTop: "3px" }}>
                    <Avatar src={user.avatarUrls[year]} size="small" username={user.username} />
                </div>
                <TextareaAutosize
                    autoFocus={props.focus}
                    onChange={(e) => props.onChange(e.currentTarget.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => handleKeyDown(e)}
                    value={props.value}
                    className="form-control w-100 ms-2"
                    maxLength={POST_COMMENT_MAX_LENGTH}
                    style={{ resize: "none" }}
                    placeholder={`Write a ${props.type}...`}
                    disabled={props.isBusy}
                    minRows={1}
                    maxRows={10}
                />
            </div>
            <div className="d-flex justify-content-between mt-1">
                {props.isBusy && (
                    <small>
                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-1" /> We are
                        publishing your {props.type}...
                    </small>
                )}
                {!props.isBusy && <small className="mt-1">Press Enter to post.</small>}
                <TextLimitCounter count={props.value.length} limit={POST_COMMENT_MAX_LENGTH} />
            </div>
        </div>
    );
};
