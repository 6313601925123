import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";
import Button, { ButtonProps } from "react-bootstrap/Button";

import { useSignedInClickHandler } from "../../hooks/useSignedInClickHandler";
import { AnimatedCounter } from "../atoms/AnimatedCounter";

interface Props extends ButtonProps {
    clicked: boolean;
    count: number;
    onClickWithAuthHandling?: () => void;
}

export const NostalgiaBtn: FC<Props> = (props) => {
    const clickHandler = useSignedInClickHandler();

    let variant = "light";
    if (props.clicked) {
        variant = "outline-success";
    }

    return (
        <Button onClick={() => clickHandler(() => props && props.onClickWithAuthHandling())} variant={variant} size="sm" {...props}>
            <FontAwesomeIcon className="me-2" icon={props.clicked ? faHeartSolid : faHeartRegular} fixedWidth />
            <AnimatedCounter className="d-inline" clicked={props.clicked} count={props.count} />
        </Button>
    );
};
