import { faFacebook, faFacebookMessenger, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faFlag, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisH, faLink, faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import mobile from "is-mobile";
import Link from "next/link";
import React, { FC, Ref, forwardRef, useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TextTransition, { presets } from "react-text-transition";

import { AppPath } from "../../appPath";
import { useUser } from "../../auth/authContext";
import { useYear } from "../../main/hooks/useYear";
import { isClientSide } from "../../main/utils/isClientSide";
import { ReportContentModal } from "../modals/ReportContentModal";

// eslint-disable-next-line react/display-name
const CustomToggle = forwardRef((props: any, ref: Ref<HTMLDivElement>) => (
    <div
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
        }}
        className="clickable text-muted"
    >
        <FontAwesomeIcon icon={faEllipsisH} />
    </div>
));

interface Props {
    id: string;
    name: "post" | "comment";
    onDelete?: () => void;
    username: string;
}

const copyLinkStr = "Copy link";

export const PostOrCommentOptionsDropdown: FC<Props> = (props) => {
    const { user } = useUser();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [copyLinkText, setCopyLinkText] = useState(copyLinkStr);
    const copyLinkTextTimeout = useRef<NodeJS.Timeout>();
    const year = useYear();
    const link = process.env.NEXT_PUBLIC_DOMAIN + AppPath.post(year, props.username, props.id);

    useEffect(() => {
        return () => {
            if (copyLinkTextTimeout.current) {
                clearTimeout(copyLinkTextTimeout.current);
            }
        };
    }, []);

    function handleDeleteClicked() {
        setShowDeleteConfirmation(false);
        props.onDelete();
    }

    function shareToFacebook() {
        return `https://www.facebook.com/sharer/sharer.php?app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&u=${encodeURIComponent(link)}`;
    }

    function shareToMessenger() {
        if (isClientSide() && mobile({ tablet: true })) {
            return `fb-messenger://share/?app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&link=${encodeURIComponent(link)}`;
        }

        return `https://www.facebook.com/dialog/send?app_id=${process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}&link=${encodeURIComponent(
            link
        )}&redirect_uri=${encodeURIComponent(link)}`;
    }

    function shareToTwitter() {
        return `https://twitter.com/share?text=${encodeURIComponent("See this post by @" + props.username)}&url=${encodeURIComponent(
            link
        )}`;
    }

    function shareViaEmail() {
        return `mailto:?subject=See this post by @${props.username}&body=See this post by @${props.username}: ${link}`;
    }

    function handleLinkCopied() {
        setCopyLinkText("Copied!");
        if (copyLinkTextTimeout.current) {
            clearTimeout(copyLinkTextTimeout.current);
        }
        copyLinkTextTimeout.current = setTimeout(() => {
            setCopyLinkText(copyLinkStr);
        }, 1500);
    }

    return (
        <>
            <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation required</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this {props.name}?<br />
                    You won&apos;t be able to restore it.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={() => setShowDeleteConfirmation(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleDeleteClicked}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

            <ReportContentModal id={props.id} name={props.name} show={showReportModal} onHide={() => setShowReportModal(false)} />

            <Dropdown>
                <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

                <Dropdown.Menu>
                    {props.name === "post" && (
                        <>
                            <a className="dropdown-item" href={shareToFacebook()} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-2" fixedWidth icon={faFacebook} />
                                Share to Facebook
                            </a>
                            <a className="dropdown-item" href={shareToMessenger()} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-2" fixedWidth icon={faFacebookMessenger} />
                                Share to Messenger
                            </a>
                            <a className="dropdown-item" href={shareToTwitter()} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon className="me-2" fixedWidth icon={faTwitter} />
                                Share to Twitter
                            </a>
                            <a className="dropdown-item" href={shareViaEmail()}>
                                <FontAwesomeIcon className="me-2" fixedWidth icon={faEnvelope} />
                                Share via Email
                            </a>
                            <CopyToClipboard text={link} onCopy={() => handleLinkCopied()}>
                                <div className="dropdown-item clickable">
                                    <FontAwesomeIcon className="me-2" fixedWidth icon={faLink} />
                                    <TextTransition className="d-inline" text={copyLinkText} springConfig={presets.default} />
                                </div>
                            </CopyToClipboard>
                            <div className="dropdown-item clickable">
                                <Link href={link} passHref>
                                    <a className="text-reset">
                                        <FontAwesomeIcon className="me-2" fixedWidth icon={faShare} />
                                        Go to post
                                    </a>
                                </Link>
                            </div>
                            <Dropdown.Divider />
                        </>
                    )}

                    <Dropdown.Item as="button" onClick={() => setShowReportModal(true)}>
                        <FontAwesomeIcon className="me-2" fixedWidth icon={faFlag} />
                        Report this {props.name}
                    </Dropdown.Item>
                    {user && props.onDelete && props.username === user.username && (
                        <Dropdown.Item as="button" className="text-danger" onClick={() => setShowDeleteConfirmation(true)}>
                            <FontAwesomeIcon className="me-2" fixedWidth icon={faTrashAlt} />
                            Delete
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};
