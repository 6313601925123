import { withErrorBoundary } from "@sentry/nextjs";
import * as Sentry from "@sentry/nextjs";
import React, { FC, useState } from "react";

import { Fallback } from "../../main/components/Fallback";
import { CommonErrorMsg } from "../../main/language-helpers/errorMessages";
import { BaseProps } from "../../main/models/props/baseProps";
import { showToast } from "../../main/services/toastService";
import { PostCommentDto } from "../models/dto/postCommentDto";
import { PostDto } from "../models/dto/postDto";
import { postService } from "../services/postService";
import { CommentOrReply } from "./CommentOrReply";

interface Props extends BaseProps {
    post: PostDto;
    comment: PostCommentDto;
    focus: boolean;
    onPublished?: (comment: PostCommentDto) => void;
}

let WriteReply: FC<Props> = (props) => {
    const [text, setText] = useState("");
    const [isBusy, setIsBusy] = useState(false);

    async function publish() {
        setIsBusy(true);

        let published: PostCommentDto;
        try {
            const { data } = await postService.replyToComment(props.post.epochStartingYear, props.post.id, props.comment.id, { text });
            published = data;
        } catch (error) {
            showToast(CommonErrorMsg.occurredTryLater);
            Sentry.captureException(error);
            return;
        } finally {
            setIsBusy(false);
        }

        if (props.onPublished) {
            props.onPublished(published);
        }

        setText("");
    }

    return (
        <CommentOrReply
            className={props.className}
            value={text}
            onChange={setText}
            type="reply"
            focus={props.focus}
            isBusy={isBusy}
            onPublish={publish}
        />
    );
};

WriteReply = withErrorBoundary(WriteReply, { fallback: <Fallback>Writing replies is temporarily unavailable.</Fallback> });
export { WriteReply };
