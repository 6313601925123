import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp as faThumbsUpSolid } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/nextjs";
import classNames from "classnames";
import Link from "next/link";
import React, { FC, useEffect, useState } from "react";

import { AppPath } from "../../appPath";
import { AnimatedCounter } from "../../main/components/atoms/AnimatedCounter";
import { Avatar } from "../../main/components/Avatar";
import { GreyTextBtn } from "../../main/components/buttons/GreyTextBtn";
import { TimeAgo } from "../../main/components/date/TimeAgo";
import { useSignedInClickHandler } from "../../main/hooks/useSignedInClickHandler";
import { useYear } from "../../main/hooks/useYear";
import { showToast } from "../../main/services/toastService";
import { ReportContentModal } from "../modals/ReportContentModal";
import { NostalgiaLikeReactionDto } from "../models/dto/nostalgiaLikeReactionDto";
import { ReactionDto } from "../models/dto/reactionDto";
import { CommentReaction } from "../models/enums/commentReaction";
import { PostCommentView } from "../models/views/postCommentView";
import { postService } from "../services/postService";
import styles from "./PostComment.module.scss";
import { PostOrCommentOptionsDropdown } from "./PostOrCommentOptionsDropdown";

interface Props {
    postId: string;
    comment: PostCommentView;
    reply?: PostCommentView;
    canReply?: boolean;
    onReplyClick?: () => void;
    onDeleted: () => void;
    children?: React.ReactNode;
}

function getReaction(comment: PostCommentView, reply: PostCommentView, type: keyof NostalgiaLikeReactionDto): ReactionDto {
    if (reply) {
        return { count: reply.reaction[type].count, reacted: reply.reaction[type].reacted };
    }

    return { count: comment.reaction[type].count, reacted: comment.reaction[type].reacted };
}

export const PostComment: FC<Props> = ({ children, comment, reply, postId, canReply, onReplyClick, onDeleted }) => {
    const [like, setLike] = useState(getReaction(comment, reply, "like"));
    const [givingLike, setGivingLike] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [justAdded, setJustAdded] = useState(Boolean(reply ? reply.justAdded : comment.justAdded));
    const clickHandler = useSignedInClickHandler();
    const year = useYear();

    useEffect(() => {
        if (comment.justAdded) {
            const timeout = setTimeout(() => setJustAdded(false), 10000);
            return () => clearTimeout(timeout);
        }
    }, [comment.justAdded]);

    if (reply && comment.id === reply.id) {
        throw new Error("Comment and reply ids cannot be the same.");
    }

    async function handleReaction() {
        if (givingLike) {
            return;
        }

        const previous = { ...like };

        let reaction: CommentReaction;

        if (previous.reacted) {
            setLike({ count: previous.count - 1 || 0, reacted: false });
            reaction = CommentReaction.None;
        } else {
            setLike({ count: previous.count + 1, reacted: true });
            reaction = CommentReaction.Like;
        }

        setGivingLike(true);
        try {
            const { data } = reply
                ? await postService.changeCommentReplyReaction(year, postId, comment.id, reply.id, { reaction })
                : await postService.changeCommentReaction(year, postId, comment.id, { reaction });
            setLike({ count: data.like.count, reacted: data.like.reacted });
        } catch (error) {
            setLike(previous);
            showToast("An error occurred while changing your comment reaction.");
            Sentry.captureException(error);
        } finally {
            setGivingLike(false);
        }
    }

    async function deleteComment() {
        try {
            if (reply) {
                await postService.deleteCommentReply(year, postId, comment.id, reply.id);
            } else {
                await postService.deleteComment(year, postId, comment.id);
            }
        } catch (error) {
            showToast("An error occurred while deleting the comment.");
            Sentry.captureException(error);
            return;
        }
        onDeleted();
    }

    const thisComment = reply ? reply : comment;

    return (
        <>
            {/* TODO is this HTML code a duplication? */}
            <ReportContentModal id={thisComment.id} name="comment" show={showReportModal} onHide={() => setShowReportModal(false)} />

            <article
                onClick={() => setJustAdded(false)}
                className={classNames(styles["post-comment"], "d-flex mt-4", { "border-start border-primary": justAdded })}
            >
                <Avatar className="mt-2" src={thisComment.author.avatarUrl} size="small" username={thisComment.author.username} />

                <div className="ms-2 w-100">
                    <div className="py-2 px-3 rounded bg-light" style={{ maxWidth: "max-content" }}>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <Link href={AppPath.userProfile(year, thisComment.author.username)} passHref>
                                <a className="fw-bold me-4">{thisComment.author.username}</a>
                            </Link>

                            <PostOrCommentOptionsDropdown
                                id={thisComment.id}
                                name="comment"
                                username={thisComment.author.username}
                                onDelete={deleteComment}
                            />
                        </div>

                        <div className="mt-1 text-multiline">{thisComment.text}</div>
                    </div>

                    <div className={`${styles["comment-buttons"]} d-flex mt-1 bullets text-secondary`}>
                        <span className={classNames({ clickable: !givingLike })} onClick={() => clickHandler(handleReaction)}>
                            <FontAwesomeIcon
                                className={classNames({ "text-primary": like.reacted }, "me-1")}
                                icon={like.reacted ? faThumbsUpSolid : faThumbsUp}
                                fixedWidth
                            />
                            <AnimatedCounter className="d-inline" clicked={like.reacted} count={like.count} />
                        </span>

                        {canReply && <GreyTextBtn onClick={() => clickHandler(onReplyClick)}>Reply</GreyTextBtn>}

                        <TimeAgo date={thisComment.publishedAt} />
                    </div>

                    <div>{children}</div>
                </div>
            </article>
        </>
    );
};
