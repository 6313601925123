import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withErrorBoundary } from "@sentry/nextjs";
import React, { FC, useState } from "react";

import { useUser } from "../../auth/authContext";
import { GreyTextBtn } from "../../main/components/buttons/GreyTextBtn";
import { LoadingText } from "../../main/components/loadingScreens/LoadingText";
import { useComments } from "../hooks/useComments";
import { PostDto } from "../models/dto/postDto";
import { PostCommentView } from "../models/views/postCommentView";
import { postService } from "../services/postService";
import { PostComment } from "./PostComment";
import styles from "./PostCommentWithReplies.module.scss";
import { SomethingWentWrong } from "./SomethingWentWrong";
import { WriteReply } from "./WriteReply";

interface Props {
    post: PostDto;
    comment: PostCommentView;
    onDeleted: () => void;
}

let PostCommentWithReplies: FC<Props> = ({ post, comment, onDeleted }) => {
    const [showReplies, setShowReplies] = useState(false);
    const [focus, setFocus] = useState(false);
    const { user } = useUser();
    const [replyCount, setReplyCount] = useState(comment.replyCount || 0); // TODO refactor with React Context or Zustand
    const {
        isLoading,
        comments: replies,
        handleAdded,
        handleDeleted,
        hasMore,
        loadBatch,
        error,
    } = useComments(comment.replyCount, (lastId) => postService.getCommentReplies(post.epochStartingYear, post.id, comment.id, lastId));

    function handleReplyClicked() {
        setFocus(true);
        setShowReplies(true);
        loadBatch();
    }

    function handleRepliesBtnClick() {
        setShowReplies(true);
        loadBatch();
    }

    function handleReplyDeleted(id: string) {
        const nextReplyCount = replyCount - 1 || 0;
        setReplyCount(nextReplyCount);
        if (!nextReplyCount) {
            setShowReplies(false);
        }
        handleDeleted(id);
    }

    return (
        <PostComment postId={post.id} comment={comment} canReply onReplyClick={handleReplyClicked} onDeleted={onDeleted}>
            {error && <SomethingWentWrong className="mx-4" variant="light" onTryAgain={loadBatch} />}

            {!error && (
                <>
                    {!isLoading && replies.length === 0 && replyCount > 0 && (
                        <div className="mt-2 ms-1">
                            <GreyTextBtn onClick={handleRepliesBtnClick}>
                                <FontAwesomeIcon icon={faLevelUpAlt} className="me-2" style={{ transform: "rotate(90deg)" }} />
                                {replyCount} {replyCount === 1 ? "Reply" : "Replies"}
                            </GreyTextBtn>
                        </div>
                    )}

                    <div className={styles["post-comment-replies"]}>
                        {replies.map((r) => (
                            <PostComment
                                key={"reply:" + r.id}
                                postId={post.id}
                                comment={comment}
                                reply={r}
                                onDeleted={() => handleReplyDeleted(r.id)}
                            />
                        ))}
                    </div>

                    {isLoading && <LoadingText className="fade-in" />}

                    {!isLoading && showReplies && (
                        <>
                            {hasMore && (
                                <div className="mt-2">
                                    <GreyTextBtn onClick={loadBatch}>View more replies</GreyTextBtn>
                                </div>
                            )}

                            {user && (
                                <WriteReply
                                    className="mt-3"
                                    focus={focus}
                                    post={post}
                                    comment={comment}
                                    onPublished={(i) => handleAdded(i, "asc")}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </PostComment>
    );
};

PostCommentWithReplies = withErrorBoundary(PostCommentWithReplies, {
    fallback: <SomethingWentWrong className="mx-4" variant="light" text="An error occurred while displaying this comment." />,
});
export { PostCommentWithReplies };
