import * as Sentry from "@sentry/nextjs";
import { AxiosResponse } from "axios";
import { useState } from "react";

import { PostCommentDto } from "../models/dto/postCommentDto";
import { PostCommentView } from "../models/views/postCommentView";

interface UseCommentsHook {
    isLoading: boolean;
    comments: PostCommentView[];
    handleAdded: (comment: PostCommentView, order: "asc" | "desc") => void;
    handleDeleted: (id: string) => void;
    hasMore: boolean;
    loadBatch: () => void;
    error: boolean;
}

export interface PreloadedComments {
    comments: PostCommentDto[];
    hasMore: boolean;
}

export function useComments(
    totalCount: number,
    load: (lastId: string) => Promise<AxiosResponse<PostCommentDto[]>>,
    preloaded?: PreloadedComments
): UseCommentsHook {
    const [skipFirstLoad, setSkipFirstLoad] = useState(Boolean(preloaded));
    const [comments, setComments] = useState<PostCommentView[]>(preloaded ? preloaded.comments : []);
    const [hasMore, setHasMore] = useState(preloaded ? preloaded.hasMore : false);
    const [lastId, setLastId] = useState<string>(preloaded ? preloaded.comments[preloaded.comments.length - 1].id : null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    async function loadBatch() {
        if (skipFirstLoad) {
            setSkipFirstLoad(false);
            return;
        }

        setError(false);
        setIsLoading(true);

        let data: PostCommentView[];
        try {
            const response = await load(lastId);
            data = response.data;
        } catch (error) {
            setError(true);
            Sentry.captureException(error);
            return;
        } finally {
            setIsLoading(false);
        }

        const newComments: PostCommentView[] = [];
        for (const c of comments) {
            if (!data.some((i) => i.id === c.id)) {
                newComments.push(c);
            }
        }
        newComments.push(...data);

        setComments(newComments);
        setHasMore(data.length > 0 && totalCount > newComments.length);
        if (data.length > 0) {
            setLastId(data[data.length - 1].id);
        }
    }

    function handleAdded(comment: PostCommentView, order: "asc" | "desc") {
        if (order === "asc") {
            setComments([...comments, comment]);
        } else {
            setComments([comment, ...comments]);
        }
    }

    function handleDeleted(id: string) {
        setComments(comments.filter((i) => i.id !== id));
    }

    return { isLoading, comments, handleAdded, handleDeleted, hasMore, loadBatch, error };
}
