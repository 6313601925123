import classNames from "classnames";
import { FC, useState } from "react";

import { BaseProps } from "../models/props/baseProps";

interface Props extends BaseProps {
    email: string;
}

export const ProtectedEmail: FC<Props> = ({ email, className }) => {
    const [visible, setVisible] = useState(false);

    if (!visible) {
        return (
            <span className={classNames("link-info clickable", className)} onClick={() => setVisible(true)}>
                [CLICK TO DISCLOSE]
            </span>
        );
    }

    return (
        <a className={className} href={`mailto:${email}`}>
            {email}
        </a>
    );
};
