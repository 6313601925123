import { useRouter } from "next/router";

import { AppPath } from "../../appPath";
import { useUser } from "../../auth/authContext";

export function useSignedInClickHandler() {
    const { user } = useUser();
    const router = useRouter();

    function handleClick(onClick: () => void) {
        if (!user) {
            router.push(AppPath.signIn(router.asPath));
            return;
        }
        onClick();
    }

    return handleClick;
}
